export const createCart = 
`mutation cartCreate($input: CartInput) {
  cartCreate(input: $input) {
    cart {
		appliedGiftCards {
		  amountUsed {
			amount
			currencyCode
		  }
		  balance {
		  	amount
		  	currencyCode
		  }
		  id
		  lastCharacters
		  presentmentAmountUsed {
		  	amount
		  	currencyCode
		  }
		}
		id
		createdAt
		updatedAt
		checkoutUrl
		note
		lines(first: 250) {
		    nodes {
		      id
			  cost {
				amountPerQuantity {
			      amount
			      currencyCode
			    }
				compareAtAmountPerQuantity {
				  amount
				  currencyCode
				}
				subtotalAmount {
			      amount
			      currencyCode
			    }
				totalAmount	{
			      amount
			      currencyCode
			    }
			  }
		      quantity
		      merchandise {
				... on ProductVariant {
		          id
				  title
				  quantityAvailable
				  image {
					altText
					height
					width
					id
					url
				  }
				  product {
					id
					title
					productType
				  }
				}
		      }
		      attributes {
		        key
		        value
		      }
		    }
		}
		totalQuantity
		attributes {
		  key
		  value
		}
		cost {
		  totalAmount {
		    amount
		    currencyCode
		  }
		  subtotalAmount {
		    amount
		    currencyCode
		  }
		  totalTaxAmount {
		    amount
		    currencyCode
		  }
		  totalDutyAmount {
		    amount
		    currencyCode
		  }
		}
		buyerIdentity {
		  email
		  phone
		  customer {
		    id
		  }
		  countryCode
		}
    }
    userErrors {
      field
      message
    }
  }
}`
 
export const fetchCart =
`query($id: ID!) {
  cart(id: $id) {
	appliedGiftCards {
	  amountUsed {
		amount
		currencyCode
	  }
	  balance {
	  	amount
	  	currencyCode
	  }
	  id
	  lastCharacters
	  presentmentAmountUsed {
  	  	amount
  	  	currencyCode
  	  }
	}
	id
    createdAt
    updatedAt
    checkoutUrl
	note
    lines(first: 250) {
	    nodes {
	      id
		  cost {
			amountPerQuantity {
		      amount
		      currencyCode
		    }
			compareAtAmountPerQuantity {
			  amount
			  currencyCode
			}
			subtotalAmount {
		      amount
		      currencyCode
		    }
			totalAmount	{
		      amount
		      currencyCode
		    }
		  }
	      quantity
	      merchandise {
			... on ProductVariant {
	          id
			  title
			  quantityAvailable
			  image {
				altText
				height
				width
				id
				url
			  }
			  product {
				id
				title
				productType
			  }
			}
	      }
	      attributes {
	        key
	        value
	      }
	    }
    }
	totalQuantity
    attributes {
      key
      value
    }
    cost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
    }
    buyerIdentity {
      email
      phone
      customer {
        id
      }
      countryCode
    }
  }
}`

export const addToCartQuery =
`mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
		appliedGiftCards {
		  amountUsed {
			amount
			currencyCode
		  }
		  balance {
		  	amount
		  	currencyCode
		  }
		  id
		  lastCharacters
		  presentmentAmountUsed {
		  	amount
		  	currencyCode
		  }
		}
		id
		createdAt
		updatedAt
		checkoutUrl
		note
		lines(first: 250) {
		    nodes {
		      id
			  cost {
				amountPerQuantity {
			      amount
			      currencyCode
			    }
				compareAtAmountPerQuantity {
				  amount
				  currencyCode
				}
				subtotalAmount {
			      amount
			      currencyCode
			    }
				totalAmount	{
			      amount
			      currencyCode
			    }
			  }
		      quantity
		      merchandise {
				... on ProductVariant {
		          id
				  title
				  quantityAvailable
				  image {
					altText
					height
					width
					id
					url
				  }
				  product {
					id
					title
					productType
				  }
				}
		      }
		      attributes {
		        key
		        value
		      }
		    }
		}
		totalQuantity
		attributes {
		  key
		  value
		}
		cost {
		  totalAmount {
		    amount
		    currencyCode
		  }
		  subtotalAmount {
		    amount
		    currencyCode
		  }
		  totalTaxAmount {
		    amount
		    currencyCode
		  }
		  totalDutyAmount {
		    amount
		    currencyCode
		  }
		}
		buyerIdentity {
		  email
		  phone
		  customer {
		    id
		  }
		  countryCode
		}
    }
  }
}`

export const removeItemFromCartQuery =
`mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
		appliedGiftCards {
		  amountUsed {
			amount
			currencyCode
		  }
		  balance {
		  	amount
		  	currencyCode
		  }
		  id
		  lastCharacters
		  presentmentAmountUsed {
		  	amount
		  	currencyCode
		  }
		}
		id
		createdAt
		updatedAt
		checkoutUrl
		note
		lines(first: 250) {
		    nodes {
		      id
			  cost {
				amountPerQuantity {
			      amount
			      currencyCode
			    }
				compareAtAmountPerQuantity {
				  amount
				  currencyCode
				}
				subtotalAmount {
			      amount
			      currencyCode
			    }
				totalAmount	{
			      amount
			      currencyCode
			    }
			  }
		      quantity
		      merchandise {
				... on ProductVariant {
		          id
				  title
				  quantityAvailable
				  image {
					altText
					height
					width
					id
					url
				  }
				  product {
					id
					title
					productType
				  }
				}
		      }
		      attributes {
		        key
		        value
		      }
		    }
		}
		totalQuantity
		attributes {
		  key
		  value
		}
		cost {
		  totalAmount {
		    amount
		    currencyCode
		  }
		  subtotalAmount {
		    amount
		    currencyCode
		  }
		  totalTaxAmount {
		    amount
		    currencyCode
		  }
		  totalDutyAmount {
		    amount
		    currencyCode
		  }
		}
		buyerIdentity {
		  email
		  phone
		  customer {
		    id
		  }
		  countryCode
		}
    }
  }
}`

export const updateItemToCartQuery =
`mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
	cart {
		appliedGiftCards {
		  amountUsed {
			amount
			currencyCode
		  }
		  balance {
		  	amount
		  	currencyCode
		  }
		  id
		  lastCharacters
		  presentmentAmountUsed {
		  	amount
		  	currencyCode
		  }
		}
		id
		createdAt
		updatedAt
		checkoutUrl
		note
		lines(first: 250) {
		    nodes {
		      id
			  cost {
				amountPerQuantity {
			      amount
			      currencyCode
			    }
				compareAtAmountPerQuantity {
				  amount
				  currencyCode
				}
				subtotalAmount {
			      amount
			      currencyCode
			    }
				totalAmount	{
			      amount
			      currencyCode
			    }
			  }
		      quantity
		      merchandise {
				... on ProductVariant {
		          id
				  title
				  quantityAvailable
				  image {
					altText
					height
					width
					id
					url
				  }
				  product {
					id
					title
					productType
				  }
				}
		      }
		      attributes {
		        key
		        value
		      }
		    }
		}
		totalQuantity
		attributes {
		  key
		  value
		}
		cost {
		  totalAmount {
		    amount
		    currencyCode
		  }
		  subtotalAmount {
		    amount
		    currencyCode
		  }
		  totalTaxAmount {
		    amount
		    currencyCode
		  }
		  totalDutyAmount {
		    amount
		    currencyCode
		  }
		}
		buyerIdentity {
		  email
		  phone
		  customer {
		    id
		  }
		  countryCode
		}
	}
  }
}`

export const updateNoteToCartQuery =
`mutation cartNoteUpdate($cartId: ID!, $note: String!) {
  cartNoteUpdate(cartId: $cartId, note: $note) {
    cart {
		appliedGiftCards {
		  amountUsed {
			amount
			currencyCode
		  }
		  balance {
		  	amount
		  	currencyCode
		  }
		  id
		  lastCharacters
		  presentmentAmountUsed {
		  	amount
		  	currencyCode
		  }
		}
		id
		createdAt
		updatedAt
		checkoutUrl
		note
		lines(first: 250) {
		    nodes {
		      id
			  cost {
				amountPerQuantity {
			      amount
			      currencyCode
			    }
				compareAtAmountPerQuantity {
				  amount
				  currencyCode
				}
				subtotalAmount {
			      amount
			      currencyCode
			    }
				totalAmount	{
			      amount
			      currencyCode
			    }
			  }
		      quantity
		      merchandise {
				... on ProductVariant {
		          id
				  title
				  quantityAvailable
				  image {
					altText
					height
					width
					id
					url
				  }
				  product {
					id
					title
					productType
				  }
				}
		      }
		      attributes {
		        key
		        value
		      }
		    }
		}
		totalQuantity
		attributes {
		  key
		  value
		}
		cost {
		  totalAmount {
		    amount
		    currencyCode
		  }
		  subtotalAmount {
		    amount
		    currencyCode
		  }
		  totalTaxAmount {
		    amount
		    currencyCode
		  }
		  totalDutyAmount {
		    amount
		    currencyCode
		  }
		}
		buyerIdentity {
		  email
		  phone
		  customer {
		    id
		  }
		  countryCode
		}
    }
  }
}`
