import * as React from "react"
import {createStorefrontApiClient} from '@shopify/storefront-api-client'

import { 
	createCart, 
	fetchCart, 
	addToCartQuery, 
	removeItemFromCartQuery, 
	updateItemToCartQuery,
	updateNoteToCartQuery
 } from "../queries/shopify/storefront/cart"

const storefrontClient = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION,
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
})

const defaultValues = {
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  addMultiVariantsToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  updateOrderNote: () => {},
  storefrontClient,
  checkout: {
    lineItems: [],
  },
  cart: {},
  customerAccessToken: null,
  isCartExists: () => {}
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localCartKey = `shopify_cart_id`

export const StoreProvider = ({ children }) => {
	
  const getlocalStorage = (value) => {
        try {
            return JSON.parse(localStorage.getItem(value))
        } catch (e) {
            return ''
        }
    }
	
  const [cart, setCart] = React.useState(defaultValues.cart)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [customerAccessToken, setCustomerAccessToken] = React.useState(getlocalStorage('customerAccessToken'))
  const [userAccessToken, setUserAccessToken] = React.useState(getlocalStorage('customerAccessToken')?.accessToken)
  
  const setCartItem = (cart) => {
	if (isBrowser) {
	  localStorage.setItem(localCartKey, cart.id)
	}
	
	setCart(cart)
  }
  
  const updateCustomerAccessToken = (value) => {
	    isBrowser && localStorage.setItem('customerAccessToken', JSON.stringify(value))
	    setCustomerAccessToken(value)
	    setUserAccessToken(value.accessToken)
	}

  React.useEffect(() => {
	
  const initializeCart = async () => {
      const existingCartID = isBrowser
        ? localStorage.getItem(localCartKey)
        : null

      if (existingCartID && existingCartID !== `null`) {
        try {
			const {data, errors} = await storefrontClient.request(fetchCart, {
			  variables: {
			    id: existingCartID,
			  }
			})

			if (!errors && data.cart) {
			  setCartItem(data.cart)
			  return
			}

        } catch (e) {
          localStorage.setItem(localCartKey, null)
        }
      }
	  
	  const {data, errors} = await storefrontClient.request(createCart, {
		variables: {
		  input: {},
		},
	  })
		
	  if (!errors && data.cartCreate.cart) {
		setCartItem(data.cartCreate.cart)
	  }
    }

	initializeCart()
  }, [])
  
  const isCartExists = (cartId) => {

	if (cartId !== `null`) {
		try {

			return storefrontClient.request(fetchCart, {
			  variables: {
			    id: cartId,
			  }
			}).then	((res) => {
		  		if (res.data.cart === null) {
		  			return false
		  		} else {
					return true
				}
		  	})

        } catch (e) {
          //localStorage.setItem(localCartKey, null)
        }
	
	} else {
		return false
	}
  }
  
  const addVariantToCart = (variantId, quantity, attributes = '') => {
    setLoading(true)

    const cartID = cart.id
  	let lineItemsToUpdate = ''

  	if (attributes) {
  	 	lineItemsToUpdate = [
  	      {
  	        merchandiseId: variantId,
  	        quantity: parseInt(quantity, 10),
  			attributes: attributes

  	      },
  	    ]
  	} else {
  	 	lineItemsToUpdate = [
  	      {
  	        merchandiseId: variantId,
  	        quantity: parseInt(quantity, 10),
  	      },
  	    ]
  	}

	return storefrontClient.request(addToCartQuery, {
	  variables: {
	    cartId: cartID,
		lines: lineItemsToUpdate
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesAdd.cart)
			setLoading(false)
			setDidJustAddToCart(true)
			setTimeout(() => setDidJustAddToCart(false), 3000)
		}
	})
  }  
  
  const addMultiVariantsToCart = (variants) => {
    setLoading(true)

    const cartID = cart.id
    let lineItemsToUpdate = []
  	
  	variants.forEach((variant) => {
  		let itemToAdd = ''
  		
  		if (variant.customAttributes) {
  	  	 	itemToAdd = {
  	  	        merchandiseId: variant.variantId,
  	  	        quantity: parseInt(variant.quantity, 10),
  	  			attributes: variant.customAttributes
  	  	     }
  	  	} else {
  			itemToAdd = {
  	  	        merchandiseId: variant.variantId,
  	  	        quantity: parseInt(variant.quantity, 10),
  	  	     }
  	  	}
  		lineItemsToUpdate.push(itemToAdd)
  	})
	
	return storefrontClient.request(addToCartQuery, {
	  variables: {
	    cartId: cartID,
		lines: lineItemsToUpdate
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesAdd.cart)
			setLoading(false)
			setDidJustAddToCart(true)
			setTimeout(() => setDidJustAddToCart(false), 3000)
		}
	})
  }
  
  const removeLineItem = (cartID, lineItemID) => {
    setLoading(true)

	return storefrontClient.request(removeItemFromCartQuery, {
	  variables: {
	    cartId: cartID,
		lineIds: lineItemID
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesRemove.cart)
			setLoading(false)
		}
	})
  }
  
  const updateLineItem = (cartID, lineItemID, quantity) => {
	setLoading(true)
	
	const lineItemsToUpdate = [
		{ id: lineItemID, quantity: parseInt(quantity, 10) },
	]
	
	return storefrontClient.request(updateItemToCartQuery, {
	  variables: {
	    cartId: cartID,
		lines: lineItemsToUpdate
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesUpdate.cart)
			setLoading(false)
		}
	})
  }
  
  const updateOrderNote = (cartID, note) => {
	setLoading(true)
		
	return storefrontClient.request(updateNoteToCartQuery, {
	  variables: {
	    cartId: cartID,
		note: note
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartNoteUpdate.cart)
			setLoading(false)
		}
	})
  }
  
  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
		addMultiVariantsToCart,
        removeLineItem,
        updateLineItem,
		updateOrderNote,
		cart,
        loading,
        didJustAddToCart,
        customerAccessToken,
        updateCustomerAccessToken,
        userAccessToken,
		isCartExists
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
